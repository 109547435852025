import { Action } from '@ngrx/store'
import { DateRange } from 'models/date-range'

export enum InsightsActionType {
  SET_DATE_FROM = '[INSIGHTS] SET DATE FROM',
}

export class SetDateFrom implements Action {
  readonly type = InsightsActionType.SET_DATE_FROM

  constructor(public payload: DateRange) {}
}

export type InsightsAction = SetDateFrom
